export default function usePlanTiers() {
  return {
    0: 10,
    5: 500,
    10: 5_000,
    20: 25_000,
    50: 50_000,
    100: 100_000,
    250: 250_000,
    500: 500_000,
    1_000: 1_000_000,
    1_500: Infinity,
  } as Record<number, number>
}
